import * as PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import AddToList from "../Common/table/commons/AddToList";
import EntityImage from "../Entities/EntityImage";
import AuthorityInfoIcon from "../Entities/Items/InfoIcons/AuthorityInfoIconAsync";
import HasGuestsIcon from "../Entities/Items/InfoIcons/HasGuestsIconAsync";

import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcastTitle: {
    ...gStyles.avalonBold,
    display: "block",
    textAlign: "start",
    fontSize: "0.875rem",
    color: colours.white,
    textWrap: "noWrap",
    maxWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 0,

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 158,
    },
  },
  imagePlaceholder: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    aspectRatio: "1 / 1",
  },
  dataContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    display: "grid",
    gridTemplateRows: "1fr max-content",
    top: 0,
    padding: "0.5rem",
    borderRadius: 8,

    ":hover": {
      background: "linear-gradient(to bottom, #1B003300 0%, #1B0033CC 80%)",
    },
  },
  statsContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "80px max-content",
    alignItems: "center",
    gridGap: "0.5rem",
    textAlign: "start",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      gridTemplateColumns: "114px max-content",
    },
    [ScreenSizes.xlAndAbove]: {
      gridTemplateColumns: "145px max-content",
    },
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
};
const ellipsisActionStyles = {
  addToListIcon: {
    background: "#E6E1EA",
    borderRadius: "50%",
    height: "1.5rem",
    width: "1.5rem",
    fontSize: "0.613rem",
    marginLeft: "auto",
  },
};

const PodcastGridItem = (props) => {
  const {
    children,
    className,
    disableLink,
    entityLinkStyles,
    fullWidth,
    imageSize,
    imgProps,
    onClick,
    podcast,
    imageWidth,
    disablePopup,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("tiny");
  const { css, styles } = useStyles(baseStyles, props);

  const [hoverRef, isHovered] = useHover();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(podcast);
    }
  }, [onClick, podcast]);

  const imageHeight = mobile ? "23.3rem" : imageWidth;

  const powerScore = getPodcastAuthorityLevel(podcast, 0);
  const hasGuest = getPodcastHasGuests(podcast, 0);
  const hasPro = useUserHasPro();

  const podcastUrl = hasPro
    ? getPodcastUrl(podcast, "/insights")
    : getPodcastUrl(podcast);

  const showHasGuest = hasPro && hasGuest;
  const showPowerscore = hasPro && powerScore;

  const authorityProps = {
    type: "authority",
    value: powerScore,
    to: getPodcastUrl(podcast, "/insights"),
    noIcon: true,
  };

  const hasGuestProps = {
    type: "hasGuests",
    value: hasGuest,
    to: getPodcastUrl(podcast, "/insights"),
    proOnly: true,
  };

  const handlePropogation = (e) => {
    e.preventDefault();
    return null;
  };

  return (
    <div className={css(styles.container)}>
      <EntityImage
        disablePopup={disablePopup}
        className={className}
        styles={entityLinkStyles}
        entity={podcast}
        entity_type="podcast"
        onClick={handleClick}
        size={imageSize}
        fullWidth={fullWidth}
        disableLink={disableLink}
        imgProps={{
          loader: <div className={css(styles.imagePlaceholder)} />,
          ...imgProps,
        }}
        height={imageHeight}
      >
        {children}
      </EntityImage>
      <Link
        to={podcastUrl}
        className={css(styles.dataContainer)}
        ref={hoverRef}
      >
        <div className={css(styles.statsContainer)}>
          {showPowerscore && <AuthorityInfoIcon {...authorityProps} />}
          {showHasGuest && <HasGuestsIcon {...hasGuestProps} />}
        </div>
        {isHovered && (
          <div className={css(styles.titleContainer)}>
            <h3 className={css(styles.podcastTitle)}>
              {podcast?.get("title")}
            </h3>
            <div onClick={handlePropogation}>
              <AddToList
                entity={podcast}
                entityId={podcast?.get("id")}
                entityType={"podcast"}
                styles={ellipsisActionStyles}
              />
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};

PodcastGridItem.propTypes = {
  children: PropTypes.node,
  disablePopup: PropTypes.bool,
  showTitles: PropTypes.bool,
  showBadges: PropTypes.bool,
  showAuthority: PropTypes.bool,
  imageWidth: PropTypes.string,
  className: PropTypes.string,
  disableLink: PropTypes.bool,
  entityLinkStyles: PropTypes.object,
  fullWidth: PropTypes.bool,
  imageSize: PropTypes.number,
  imgProps: PropTypes.object,
  onClick: PropTypes.func,
  podcast: PropTypes.object.isRequired,
};

PodcastGridItem.defaultProps = {
  className: "",
  entityLinkStyles: undefined,
  onClick: undefined,
};

export default memo(PodcastGridItem);
