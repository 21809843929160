import Loadable from "@loadable/component";
const AuthorityInfoIcon = Loadable(() => import("./AuthorityInfoIcon"));

function InfoIconAsyncLoading() {
  return null;
}

const AuthorityInfoIconContainer = (props) => {
  return <AuthorityInfoIcon {...props} fallback={<InfoIconAsyncLoading />} />;
};

export default AuthorityInfoIconContainer;
