import Loadable from "@loadable/component";
const HasGuestsIcon = Loadable(() => import("./HasGuestsIcon"));

function HasGuestsIconAsyncLoading() {
  return null;
}

const HasGuestsIconContainer = (props) => {
  return <HasGuestsIcon {...props} fallback={<HasGuestsIconAsyncLoading />} />;
};

export default HasGuestsIconContainer;
